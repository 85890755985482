@import url('../styles/colors.css');
@import url('../styles/icons.css');
@import url('../styles/fonts.css');

.main-container {
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: var(--background-color);
    }
  
  /* 네비게이션 바 스타일 */
  .nav-bar {
    width: 80px;
    min-width: 80px;
    height: 90%;
    /* background-color: #228be6; */
    background-color: var(--brand-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    margin-top: 20px;
    margin-left: 30px;
    border-radius: 10px;
  }
  
  .nav-icon {
    width: 40px;
    height: 40px;
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
  }
  
  .nav-icon.active {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
  }


  /* 메인 프레임 영역 */
  .main-frame {
    /*width: 100%;*/
    flex: 1;
    height: 90%;
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
  }


  






  
  /* 환자 목록 패널 스타일 */
  .patient-panel {
    width: 280px;
    height: 90%;
    background-color: white;
    /* border-right: 1px solid #dee2e6; */
    padding: 20px 0;
    border-radius: 10px;
    margin-left: 20px;
    margin-right: 10px;
  }
  
  .panel-header {
    padding: 20px;
    border-bottom: 1px solid #dee2e6;
  }
  
  .tab-group {
    display: flex;
    gap: 10px;
    margin-top: 15px;
  }
  
  .tab {
    padding: 5px 10px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 14px;
    color: #868e96;
  }
  
  .tab.active {
    color: #228be6;
    font-weight: bold;
  }
  
  .patient-list {
    padding: 10px;
  }
  
  .patient-item {
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
  }
  
  .status-dot {
    width: 8px;
    height: 8px;
    background-color: #40c057;
    border-radius: 50%;
  }
  
  .chat-button {
    margin-left: auto;
    padding: 5px 15px;
    border: 1px solid #dee2e6;
    border-radius: 15px;
    background-color: white;
    color: #228be6;
    cursor: pointer;
  }
  
  /* 정보 패널 스타일 */
  .info-panel {
    width: 300px;
    height: 45%;
    background-color: white;
    /* border-right: 1px solid #dee2e6; */
    padding: 20px 0;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  
  .info-header {
    padding: 20px;
    border-bottom: 1px solid #dee2e6;
  }
  
  .info-content {
    padding: 20px;
  }
  
  .info-group {
    margin-bottom: 15px;
  }
  
  .info-group label {
    display: block;
    margin-bottom: 5px;
    color: #495057;
  }
  
  .info-group input,
  .info-group textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    background-color: #f8f9fa;
  }


  /* 이전 진료 내역 패널 */
.history-panel {
    width: 280px;
    height: 45%;
    background-color: aqua;
    /* border-right: 1px solid #dee2e6; */
    padding: 20px 0;
    border-radius: 10px;
    margin-left: 20px;
    margin-right: 10px;
  }
  
  .history-list {
    padding: 20px;
  }
  
  .history-item {
    padding: 10px;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    margin-bottom: 10px;
    background-color: #f8f9fa;
  }
  

  /* 채팅 영역 스타일 */
  .chat-area {
    flex: 1;
    height: 90%;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px 0;
    border-radius: 10px;
    margin-left: 20px;
    margin-right: 10px;
  }
  
  .chat-header {
    padding: 20px;
    border-bottom: 1px solid #dee2e6;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .end-chat {
    padding: 8px 16px;
    background-color: #228be6;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .messages {
    flex: 1;
    padding: 20px;
    background-color: #e9ecef;
    overflow-y: auto;
  }
  
  .chat-input-container {
    border-top: 1px solid #dee2e6;
    padding: 20px;
  }
  
  .suggestion-chips {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
  }
  
  .chip {
    padding: 5px 15px;
    background-color: #f1f3f5;
    border-radius: 15px;
    font-size: 14px;
  }
  
  .input-area {
    display: flex;
    gap: 10px;
  }
  
  .input-area textarea {
    flex: 1;
    padding: 12px;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    resize: none;
    height: 100px;
  }
  
  .input-area button {
    padding: 12px 24px;
    background-color: #228be6;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    height: fit-content;
    align-self: flex-end;
  }