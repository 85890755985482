/* 제목 스타일 */
.title-bar {
  text-align: right;
  font-size: 10px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  padding-right: 10px;
}

.title-bar-subtitle {
  display: flex; /* Flexbox를 사용하여 한 줄에 배치 */
  align-items: center; /* 로고와 텍스트를 수직으로 가운데 정렬 */
  margin-top: 10px; /* 상단 여백 추가 */
}

.title-logo {
  width: 20px;  /* 이미지의 너비를 50px로 설정 */
  height: auto; /* 높이를 비율에 맞춰 자동 설정 */
  margin-right: 10px; /* 이미지와 텍스트 사이의 간격 */
  padding-left: 10px;
  padding-bottom: 10px;
}

h2 {
  margin: 0; /* 기본 여백 제거 */
  font-size: 20px; /* 텍스트 크기 조절 */
  font-weight: bold; /* 폰트 굵기 */
  color: #000000; /* 텍스트 색상 */
  padding-bottom: 10px;
}
.chat-container {
  display: flex;
  height: calc(100vh - 80px);  /* 제목 영역 높이를 고려하여 전체 높이에서 조정 */
  background-color: #ffffff;
  margin-top: 10px;  /* 제목과 컨테이너 사이의 간격 */
}

.user-list {
  text-align: left;
  color: #2f2f2f;
  width: 22%;
  background-color: #fff;
  border-right: 1px solid #ddd;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* 유저 목록이 많을 경우 스크롤 가능하도록 설정 */
  display: flex;
  flex-direction: column;
}

.user-list-header-name {
  text-align: center;
  font-size: 13px;
  color: #ffffff;
  padding: 10px;
  background-color: #4a90e2;
  border-bottom: 1px solid #3a7bc8;
  margin: 0; /* 상단 여백 제거 */
}


.user-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex; /* Flexbox 사용 */
  flex-direction: column; /* 위에서 아래로 생성되도록 설정 */
}

.user-list li {
  padding: 10px;
  cursor: pointer;
}

.user-list li.selected {
  background-color: #e0e0e0;
  font-weight: bold;
}

.chat-box-container {
  display: flex;
  flex-direction: column; /* 세로 방향 레이아웃 */
  height: 100%; /* 전체 높이 */
  width: 100%;
}

.chat-box {
  flex: 1; /* 상단 채팅 박스가 나머지 공간을 채우도록 설정 */
  padding: 20px;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column-reverse; /* 최신 메시지가 아래로 오도록 설정 */
  justify-content: flex-end;
  overflow-y: auto; /* 스크롤 가능하도록 설정 */
  border: 1px solid #ddd;
  border-radius: 8px;
}

.chat-messages {
  display: flex; /* Flexbox 사용 */
  flex-direction: column; /* 세로로 쌓이도록 설정 */
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: auto; /* 스크롤 가능하도록 설정 */

}

.chat-message {
  max-width: 60%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  display: inline-block;
  word-wrap: break-word; /* 긴 단어가 있을 경우 자동 줄바꿈 */
}

.chat-message.left {
  background-color: #e1e1e1;
  align-self: flex-start; /* 왼쪽 정렬 */
}

.chat-message.right {
  background-color: #d4f0ff;
  align-self: flex-end; /* 오른쪽 정렬 */
}

.chat-message.summary {
  background-color: #c3e93c;
  align-self: flex-end; /* 오른쪽 정렬 */
  margin-bottom: 40px;
}

.session-start-time {
  font-size: 16px;
  color: #130707;
  text-align: center;
  margin: 10px 0;
  list-style-type: none;
}


/* 하단 30% 영역 스타일링 */
.chat-footer {
  height: 20%; /* 하단 영역 높이를 30%로 설정 */
  background-color: #eee;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #ddd;
  font-size: 20px; /* 텍스트 크기 조절 */
  font-weight: bold; /* 텍스트 굵기 조절 */
  overflow-y: auto; /* 스크롤 가능하도록 설정 */

}

.chat-footer ul{
  font-size: 12px;
}