:root {
    /* Brand Colors */
    --brand-color: #1D96D5;
    --brand-color-hover: #0056b3;

    --background-color: #E9EFF2;
    --background-item-box-color: #E9EFF2;

    --focus-font-color: #FFFFFF;

    --font1-color: #7B7B7B;
    --font2-color: #000000;
}