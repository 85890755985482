@font-face {
    font-family: 'AppleSDGothicNeoB';
    src: url('../fonts/AppleSDGothicNeoB.ttf') format('truetype');
    font-weight: bold;
    font-style: bold;
}

@font-face {
    font-family: 'SDGothicNeoEB';
    src: url('../fonts/AppleSDGothicNeoEB.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AppleSDGothicNeoM';
    src: url('../fonts/AppleSDGothicNeoM.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}