@import url('../styles/colors.css');
@import url('../styles/icons.css');
@import url('../styles/fonts.css');
@import url('../styles/scrollbar.css');


/* ------------------------ 전체 컨테이너 ------------------------ */
.aica-container {
    flex: 1;  /* 부모(.main-frame)의 남은 공간을 모두 차지 */
    height: 100%; /* 부모 높이의 100% */
    display: flex;
    flex-direction: column;
    background-color: var(--background-color);
    border-radius: 10px;
    /*overflow: hidden;*/
    overflow: hidden;
}

/* 아이콘 원형 스타일 */
.icon-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--brand-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  z-index: 1;
  position: relative;
}

/* 아이콘 이미지 스타일 */
.icon-mark {
  width: 20px;
  height: 20px;
  object-fit: contain;
}





/* ------------------------ 상단 헤더 ------------------------ */
.top-header {
    display: flex;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    background-color: var(--background-color);
    margin-bottom: 10px;
  }

  /* 검색창 스타일 */
  .search-box {
    position: relative;
    display: flex;
    align-items: center;
    background: white;
    border-radius: 10px;
    padding: 8px 15px;
    width: 400px;
    transition: border-radius 0.2s ease, box-shadow 0.2s ease;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  /* 팝업이 열렸을 때의 검색창 스타일 */
  .search-box.popup-open {
    border-radius: 10px 10px 0 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0) !important;
  }

  .search-box input {
    border: none;
    background: none;
    width: 100%;
    padding: 5px;
    font-size: 14px;
  }

  .search-box input:focus {
    outline: none;
  }

  .search-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-left: 8px;
  }
  
  .search-button .icon-default {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-img-search {
    /* 아이콘 이미지에 대한 스타일 */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .search-icon {
    width: 24px;
    height: 24px;
    color: #666;
    font-size: 16px;
  }

  /* 프로필 영역 스타일 */
  .profile {
    position: relative;
    width: 40px;
    height: 40px;
  }

  .profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }

  .profile-status {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 10px;
    height: 10px;
    background-color: #4CAF50;  /* 온라인 상태를 나타내는 초록색 */
    border-radius: 50%;
    border: 2px solid white;
  }

  /* 검색 팝업 스타일 */
  .search-popup {
    position: absolute;
    top: 100%;
    left: 0;
    width: 430px;
    background: white;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  .popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid #eee;
  }

  .popup-header h3 {
    margin: 0;
    font-size: 16px;
  }

  .popup-header button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #666;
  }

  .popup-content {
    max-height: 400px;
    overflow-y: auto;
    padding: 10px;
  }

  .search-result-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #eee;
  }

  .search-result-item:last-child {
    border-bottom: none;
  }

  .search-result-item .patient-name {
    flex: 1;
    font-weight: 500;
  }

  .search-result-item .patient-birth {
    color: #666;
    margin: 0 15px;
  }

  .search-result-item button {
    padding: 5px 15px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .search-result-item button:hover {
    background: #0056b3;
  }




  
/* ------------------------ 메인 영역 ------------------------ */
.aica-main {
    display: flex;
    flex: 1;
    /*flex-direction: row;
    height: 100%; */
    min-height: 0;
    margin-top: 20px;
  }





/* ------------------------ 왼쪽 섹션 (환자 목록) ------------------------ */
.left-section {
    width: 280px;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
    margin-right: 30px;
    overflow: hidden;
  }
  
  .patient-list-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    min-height: 0;  /* 오버플로우 방지 */
    overflow: hidden;
  }

  /* 환자 목록 */
  .list-content {
    flex: 1;
    /*height: calc(100% - 50px);*/  /* 헤더 높이를 제외한 나머지 */
    overflow-y: auto;  /* 내용이 넘칠 경우 스크롤 */
    min-height: 0;
  }
  
  .add-button {
    width: 20px;
    height: 20px;
    border: none;
    background: var(--background-item-box-color);
    font-size: 16px;
    color: #666;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;  /* 제목과의 간격 */
  }
  .add-button:hover {
    background: var(--background-item-box-color);
  }

  .icon-mark {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }

  .icon-img-patient-list2 {
    background-image: url('../assets/patient_list.png');  /* 아이콘 이미지 경로 */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .patient-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .patient-item {
    height: 40px;
    display: flex;
    align-items: center;
    gap: 20px;
    background-color: var(--background-item-box-color);
    padding: 10px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .patient-name {
    font-size: 12px;
  }
  
  .patient-status {
    color: #2196F3;
    margin-left: auto;
    font-size: 12px;
  }

  input[type="checkbox"] {
    width: 16px;
    height: 16px;
    border: 1px solid #ddd;
    border-radius: 2px;
  }

  .patient-item:hover {
    background-color: #f8f9fa;
  }
  
  .patient-item.selected {
    background-color: #e3f2fd;
  }
  
  .patient-name {
    font-weight: 500;
    margin-right: 8px;
    color: #333;
  }
  
  .patient-phone {
    color: #666;
    font-size: 0.9em;
    margin-right: auto;
  }

  /* 편집 모드 스타일링 */
  .edit-mode {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;
  }

  .name-input {
    flex: 1;
    padding: 6px 10px;
    border: 2px solid #007bff;
    border-radius: 4px;
    font-size: 14px;
    background-color: white;
  }

  .name-input:focus {
    outline: none;
    border-color: #0056b3;
    box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
  }

  .edit-buttons {
    display: flex;
    gap: 8px;
  }

  /* 아이콘 스타일링 */
  .save-icon, .cancel-icon, .edit-icon {
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
    transition: all 0.2s ease;
  }

  .save-icon {
    color: #28a745;
  }

  .save-icon:hover {
    background-color: #e8f5e9;
    transform: scale(1.1);
  }

  .cancel-icon {
    color: #dc3545;
  }

  .cancel-icon:hover {
    background-color: #ffebee;
    transform: scale(1.1);
  }

  .edit-icon {
    color: #6c757d;
    opacity: 0;
    transition: opacity 0.2s ease;
    margin-left: 8px;
  }

  .patient-item:hover .edit-icon {
    opacity: 0.7;
  }

  .edit-icon:hover {
    opacity: 1 !important;
    background-color: #e9ecef;
    transform: scale(1.1);
  }

  /* 통화 중 비활성화 스타일 */
  .patient-list.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  /* 반응형 디자인을 위한 미디어 쿼리 */
  @media (max-width: 768px) {
    .patient-item {
      padding: 8px 12px;
    }
    
    .patient-name, .patient-phone {
      font-size: 0.9em;
    }
    
    .edit-mode {
      flex-direction: column;
      gap: 4px;
    }
    
    .edit-buttons {
      justify-content: flex-end;
      width: 100%;
    }
  }

  


/* ------------------------ 중간 섹션 (환자 정보 / 진료 내역) ------------------------ */
.middle-section {
    width: 500px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-right: 30px;
  }
  
  .patient-info-box,
  .medical-history-box {
    background: white;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    min-height: 0;  /* 오버플로우 방지 */
  }

  /* 환자 정보, 진료 내역 */
  .info-content,
  .history-content {
    flex: 1;
    height: calc(100% - 50px);  /* 헤더 높이를 제외한 나머지 */
    overflow-y: auto;  /* 내용이 넘칠 경우 스크롤 */
    padding: 20px;
  }

  .patient-info-box {
    height:1;
  }

  .medical-history-box {
    flex: 1;
  }

  .info-row {
    display: flex;
    gap: 20px;
    margin-bottom: 25px;
  }
  
  .info-item {
    display: flex;
    flex-direction: row;
    flex: 1;
  }
  
  .info-item.full-width {
    width: 100%;
  }
  
  .info-item label {
    font-size: 14px;
    gap: 10px;
    color: #666;
    margin-right: 15px;
    margin-bottom: 5px;
    white-space: nowrap;
    display: flex;
    align-items: center;     /* 세로 중앙 정렬 */
    justify-content: center; /* 가로 중앙 정렬 */
  }
  
  .info-item input[type="text"] {
    height: 30px;
    border: 0.1px solid var(--background-item-box-color);
    border-radius: 4px;
    background-color: var(--background-item-box-color);
  }
  
  .info-item textarea {
    width: 100%;
    padding: 8px;
    border: 0.1px solid var(--background-item-box-color);
    background-color: var(--background-item-box-color);
    border-radius: 4px;
    resize: none;
    box-sizing: border-box;
    overflow-x: hidden; /* 가로 스크롤 방지 */
    overflow-y: auto;  /* 세로 스크롤 허용*/
    margin: 0;
  }
  
  .gender-options {
    display: flex;
    gap: 15px;
    align-items: center;
  }
  
  .gender-options input[type="radio"] {
    margin: 0;
  }
  
  .gender-options label {
    margin-bottom: 0;
    margin-left: 4px;
    color: var(--text-color);
    white-space: nowrap;
  }

  /* 저장 버튼이 있는 행에 대한 스타일 */
  .info-row:last-child {
    display: flex;
    justify-content: flex-end;  /* 오른쪽 정렬 */
  }

  .save-button {
    width: 100px;
    height: 40px;
    background-color: var(--background-item-box-color);
    border: none;
    border-radius: 10px;
    color: #666;
    cursor: pointer;
  }

  .save-button:hover {
    background: var(--background-item-box-color);
  }





/* ------------------------ 오른쪽 섹션 (상담 / 상담내역요약) ------------------------ */
.right-section {
    flex: 1;
    min-width: 430px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  
  /* ------------------- 상담 ------------------- */
  .chat-content-box {
    flex: 2;
    background: white;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    min-height: 0;  /* 오버플로우 방지 */
    display: flex;
    flex-direction: column;
  }

  .chat-content {
    flex: 1;
    display: flex;  /* flex container로 설정 */
    flex-direction: column;  /* 세로 방향 정렬 */
    padding: 10px 20px;  /* 전체 여백 */
    overflow-x: hidden; /* 가로 스크롤 방지 */
    overflow-y: auto;  /* 세로 스크롤 허용*/
  }
  
  

  /* 채팅 메시지 스타일 */
  .chat-message {
    margin: 10px 0;  /* 상하 여백 조정 */
    padding: 10px 0px;  /* 내부 여백 조정 */
    border-radius: 10px;
    max-width: 70%;
    word-wrap: break-word;
  }

  /* 시간 표시 스타일 */
  .session-start-time {
    text-align: center;
    color: #666;
    margin: 15px 0;  /* 상하 여백 조정 */
    font-size: 0.9em;
  }

  .chat-textarea {
    flex: 1;
    width: 100%;
    /* height: 100%; */
    resize: none;
    border: 0.1px solid var(--background-item-box-color);
    border-radius: 10px;
    background: var(--background-item-box-color);
    padding: 10px;
    margin: 0;
    box-sizing: border-box;
    
  }

  .chat-buttons {
    display: flex;
    gap: 8px;
    padding: 0 10px;
  }
  
  .chat-button {
    width: 110px;
    padding: 6px 12px;
    margin-left: 10px;
    border: none;
    border-radius: 10px;
    font-size: 14px;
    cursor: pointer;
    background: var(--background-item-box-color);
    color: #666;
  }

  .chat-button:hover {
    background: var(--background-item-box-color);
  }
  
  .chat-button.active {
    background-color: var(--brand-color);
    color: white;
  }

  
  /* ------------------- 상담 내역 요약 -------------- */
  .chat-summary-box {
    height: 200px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    /*min-height: 0;*/  /* 오버플로우 방지 */

  }

  .summary-content {
    flex: 1;
    padding: 0 20px 20px 20px;  /* 좌우하단 여백 추가 */
    min-height: 0; /* flex-grow가 작동하도록 min-height 설정 */
  }
  
  /*
  .summary-textarea {
    flex: 1;
    width: 100%;
    border: 0.1px solid var(--background-item-box-color);
    border-radius: 10px;
    background: var(--background-item-box-color);
    resize: none;
    min-height: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 10px;
    margin: 0;
    box-sizing: border-box;
  }
  */

  .summary-textarea {
    width: 100%;
    height: 90%;
    flex: 1;
    border: 0.1px solid var(--background-item-box-color);
    border-radius: 10px;
    background: var(--background-item-box-color);
    resize: none;
    min-height: 0; /* flex-grow가 작동하도록 min-height 설정 */
    padding: 10px;
    margin: 0;
    box-sizing: border-box;
    font-family: 'AppleSDGothicNeoM';
    font-size: 16px;
    line-height: 1.5;
    overflow-y: auto;  /* 내부 스크롤만 표시 */
    overflow-x: hidden;
    outline: none; /* 포커스 시 기본 아웃라인 제거 */
  }

  

  /* 요약 영역 스타일 */
  .summary-content ul {
    list-style: none;
    /* padding: 10px 15px; */
    /*margin: 0; */
    background-color: var(white); /*var(--background-item-box-color); */
    border-radius: 10px;
    /* min-height: 100px; */
  }

  .summary-content ul li {
    /*padding: 10px;  /* 리스트 아이템 내부 여백 */
    /*margin-bottom: 10px;  /* 리스트 아이템 간 간격 */
    line-height: 1.5;
    word-break: break-word;
  }

  /* 상담 요약 없을 때의 메시지 스타일 */
  .summary-content ul li:last-child {
    /*color: #666;*/
    /*text-align: left;*/
    /*padding: 15px;  /* 내부 여백 증가 */
    margin-bottom: 0;  /* 마지막 아이템은 아래 마진 제거 */
  }



/* ------------------------ 공통 스타일 ------------------------ */
  .box-header {
    padding: 15px;
    /* border-bottom: 1px solid #eee; */
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .box-header h3 {
    margin: 0;
    font-size: 22px;
    color: #000;
    font-family: 'AppleSDGothicNeoB';
    font-weight: bold;
    text-align: center;
  }

  .spacer {
    flex: 1;  /* 남은 공간을 모두 차지하여 타이틀과 버튼을 양쪽으로 밀어냄 */
  }


  /* 공통 스크롤바 스타일 */
  .summary-content::-webkit-scrollbar,
  textarea::-webkit-scrollbar,
  .chat-content::-webkit-scrollbar,
  .chat-messages::-webkit-scrollbar,
  .list-content::-webkit-scrollbar {
    width: 8px;
  }

  .summary-content::-webkit-scrollbar-track,
  textarea::-webkit-scrollbar-track,
  .chat-content::-webkit-scrollbar-track,
  .chat-messages::-webkit-scrollbar-track,
  .list-content::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .summary-content::-webkit-scrollbar-thumb,
  textarea::-webkit-scrollbar-thumb,
  .chat-content::-webkit-scrollbar-thumb,
  .chat-messages::-webkit-scrollbar-thumb,
  .list-content::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  .summary-content::-webkit-scrollbar-thumb:hover,
  textarea::-webkit-scrollbar-thumb:hover,
  .chat-content::-webkit-scrollbar-thumb:hover,
  .chat-messages::-webkit-scrollbar-thumb:hover,
  .list-content::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  


/* ------------------------ 콜센터 상담 탭 ------------------------ */

  /* 채팅 메시지 스타일 */
.chat-messages {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
}

.chat-message {
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  max-width: 70%;
  word-wrap: break-word;
}

.session-start-time {
  text-align: center;
  color: #666;
  margin: 20px 0;
  font-size: 0.9em;
}

.left {
  background-color: #e1e1e1;
  align-self: flex-start; /* 왼쪽 정렬 */
  margin-right: auto;
}

.right {
  background-color: #d4f0ff;
  align-self: flex-end; /* 오른쪽 정렬 */
  margin-left: auto;
}

.summary {
  background-color: #c3e93c;
  width: 90%;
  margin: 10px auto;
}



