/* 커스텀 스크롤바 클래스 */
.custom-scrollbar {
    overflow-y: scroll;
    overflow-x: hidden;
  }
  
  .custom-scrollbar::-webkit-scrollbar {
    width: 6px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }