@import url('../styles/colors.css');
@import url('../styles/fonts.css');

/* src/Login.css */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  form {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    font-family: "SDGothicNeoEB", sans-serif;
  }
  
  .form-group {
    font-family: "SDGothicNeoEB", sans-serif;
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input {
    width: 100%;
    padding: 8px;
    margin: 0;
    box-sizing: border-box;
  }
  
  button {
    width: 100%;
    padding: 10px;
    background-color: #a34caf;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  /* 로그인 버튼 */
  .login-button {
    font-family: "SDGothicNeoEB", sans-serif;
    width: 100%;
    padding: 10px;
    background-color: var(--brand-color);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: var(--brand-color-hover);
  }