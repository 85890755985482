.icon-logo {
    width: 40px;  /* 아이콘 크기에 맞게 조정 */
    height: 40px;
    margin-top: 30px;
    margin-bottom: 250px;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
}

.nav-icon {
    width: 40px;
    height: 40px;
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
}

.icon-default {
    width: 24px;  /* 아이콘 크기에 맞게 조정 */
    height: 24px;
    margin: 20px 0;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
}

.icon-img-aica-logo {
    background-image: url('../assets/AICA-logo.png');
}

.icon-img-home {
    background-image: url('../assets/home.png');
}

.icon-img-patient-list {
    background-image: url('../assets/patient_list.png');
}

.icon-img-search {
    background-image: url('../assets/icons8-search.svg');
}