.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 300px;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-title {
    margin-top: 0;
    color: #333;
}

.modal-message {
    color: #666;
}

.modal-button {
    padding: 8px 16px;
    background-color: var(--brand-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: "SDGothicNeoEB", sans-serif;
}

.modal-button:hover {
    background-color: var(--brand-color-hover);
}