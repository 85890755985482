@import url('../styles/colors.css');
@import url('../styles/icons.css');
@import url('../styles/fonts.css');

/* 전체 스타일 */
.patient-list-container {
    flex: 1;  /* 부모(.main-frame)의 남은 공간을 모두 차지 */
    height: 100%; /* 부모 높이의 100% */
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
  }